import * as React from "react"
import Layout from "../components/layout"
import { StaticImage } from "gatsby-plugin-image"


const IndexPage = () => {
  return (
    <Layout pageTitle="Home">
      <p>
        Thornley Associates is a partnership of seasoned building industry professionals with expertise in high-end custom homes, wineries, hotels, resorts, land development, and historic restoration.  We have done extensive residential and commercial work throughout the Napa-Sonoma region and beyond.  At Thornley Associates, we leverage our many decades of experience to the benefit of every one of our projects and clients. We don’t just build buildings, we build relationships with our clients, our preferred subcontractors, architects, designers, engineers and Building/Planning officials
      </p>
      <StaticImage
          src="../images/001.jpg"
          alt="A dinosaur"
          placeholder="blurred"
          layout="fixed"
          width={200}
          height={200}
      />
    </Layout>
  )
}

export default IndexPage
